<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">MISCELLANEOUS </span>
    </PageHeader>
    <v-form ref="form">
      <v-row justify="center">
        <v-col cols="10">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title class="subtitle-1">
                Search Student by Index Number
              </v-toolbar-title>

              <v-autocomplete
                :loading="loadStudent"
                v-model="index_number"
                :items="studentcampusBasket"
                :search-input.sync="searchStudent"
                @keyup="debouncesearchStudent"
                cache-items
                hide-details
                flat
                item-text="index_number_title_name"
                item-value="id"
                label="Index Number"
                solo-inverted
                class="mx-4"
                :rules="[(v) => !!v || 'Index number is required...']"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title v-if="loadStudent">
                      Searching
                      <strong>Student...</strong>
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      No
                      <strong>Student Found...</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <v-select
                v-model="selectedType"
                :items="getters_misc_fees"
                item-text="type"
                :menu-props="{ down: true, offsetY: true }"
                cache-items
                hide-details
                flat
                label="Select Miscellaneous Type"
                solo-inverted
                class="mx-4"
                return-object
              >
              </v-select>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>

      <v-scroll-y-transition>
        <v-row v-if="showMisc">
          <v-col>
            <v-row v-if="showResit">
              <v-col>
                <v-card>
                  <v-card-title primary-title>
                    {{ selectedType.type }}
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8">
                        <v-data-table
                          :headers="resitHeaders"
                          :items="basketWithIndex"
                          hide-default-footer
                          class="elevation-1"
                          item-key="id"
                          show-select
                          v-model="selected"
                        >
                        </v-data-table>
                      </v-col>
                      <v-col>
                        <v-text-field
                          readonly
                          class="pa-2"
                          type="number"
                          outlined
                          label="Amount Paid"
                          :rules="[numberRule]"
                          v-model.number="amount"
                          hide-details="auto"
                          :error-messages="errorMsgs"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row justify="center">
                      <v-col cols="4">
                        <v-btn
                          block
                          large
                          @click="saveresitBtn"
                          color="success"
                          :disabled="selected.length === 0"
                        >
                          SAVE
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="showOther">
              <v-col>
                <v-card>
                  <v-card-title primary-title>
                    {{ selectedType.type }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row justify="center" align="center">
                      <v-col cols="12" lg="2">
                        <v-card flat class="d-flex justify-center align-center">
                          <v-btn
                            x-large
                            fab
                            color="grey darken-2"
                            @click="decrement"
                            icon
                          >
                            <v-icon x-large>mdi-48px mdi-minus-circle </v-icon>
                          </v-btn>

                          <div
                            class="
                              d-inline
                              headline
                              d-flex
                              justify-center
                              align-center
                            "
                            style="width: 40px"
                            v-text="quantity"
                          ></div>

                          <v-btn
                            color="grey darken-2"
                            x-large
                            icon
                            fab
                            @click="increment"
                          >
                            <v-icon x-large> mdi-48px mdi-plus-circle </v-icon>
                          </v-btn>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <div>
                          <v-text-field
                            outlined
                            type="number"
                            class="rounded-0"
                            hide-details="auto"
                            label="Amount in Gh cedis"
                            v-model="amountOther"
                            readonly
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="2">
                        <v-btn
                          block
                          :disabled="saveOther"
                          @click="saveOtherMisc"
                          color="success"
                          class="mx-2"
                          height="48"
                        >
                          save
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-scroll-y-transition>
    </v-form>

    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";
  import Api from "@/apis/Api";
  export default defineComponent({
    components: { PageHeader, Response },
    setup() {
      const vm = getCurrentInstance();
      const {
        getStudentCampus,
        getStudentResit,
        assignStudentResit,
        signOut,
        getMiscFees,
      } = useActions([
        "getStudentCampus",
        "getStudentResit",
        "assignStudentResit",
        "signOut",
        "getMiscFees",
      ]);

      const {
        getters_student_campus,
        user,
        getters_student_resit,
        getters_misc_fees,
      } = useGetters([
        "getters_student_campus",
        "user",
        "getters_student_resit",
        "getters_misc_fees",
      ]);

      getMiscFees();

      const searchStudentHeader = reactive({
        overlay: false,
        response: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: null,
        numberRule: (v) => {
          if (!isNaN(parseFloat(v)) && v >= 1 && v <= 999) return true;
          return "Number has to be between 1 and 999";
        },
        showOther: false,
        errorMsgs: "",
        amount: 0.0,
        saveOther: true,
        selected: [],
        selectedType: null,
        loadStudent: false,
        index_number: null,
        studentcampusBasket: [],
        searchStudent: null,
        showResit: false,
        showMisc: false,
        resitHeaders: [
          { text: "#", value: "index" },
          {
            text: "COURSE",
            value: "header_campus_course_session.course.coursecode_coursename",
          },
          {
            text: "ACADEMIC CALENDAR",
            value:
              "header_campus_course_session.header.calendar.academic_calendar",
          },
          {
            text: "LEVEL",
            value: "header_campus_course_session.header.level.level",
          },
          {
            text: "SEMESTER",
            value: "header_campus_course_session.header.semester.semester",
          },
        ],
        form: null,
      });

      const {
        response,
        msgHeader,
        msgBody,
        msgIcon,
        color,
        overlay,
        form,
        showOther,
        searchStudent,
        studentcampusBasket,
        loadStudent,
        selectedType,
        index_number,
        showResit,
        showMisc,
        selected,
        errorMsgs,
        amount,
        saveOther,
      } = toRefs(searchStudentHeader);

      const debouncesearchStudent = debounce(() => {
        if (searchStudent.value) {
          if (studentcampusBasket.value.length > 0) return;
          loadStudent.value = true;

          const infoBasket = {
            role: user.value.role,
            campus: user.value.campus,
          };
          getStudentCampus(infoBasket)
            .then(() => {
              studentcampusBasket.value = getters_student_campus.value;
            })
            .finally(() => {
              loadStudent.value = false;
            });
        }
      });

      watch(
        () => [selectedType.value, index_number.value],
        () => {
          if (selectedType.value && index_number.value) {
            showMisc.value = true;
            if (selectedType.value.type.toLowerCase() == "resit") {
              getStudentResit(index_number.value).then(() => {
                showResit.value = true;
                showOther.value = false;
              });
              return;
            }
            showOther.value = true;
            showResit.value = false;
            return;
          }
          showMisc.value = false;
        }
      );

      watch(
        () => selected.value,
        () => {
          amount.value = selected.value.length * selectedType.value.amount;
        }
      );

      const basketWithIndex = computed(() => {
        return getters_student_resit.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      const saveresitBtn = () => {
        if (selected.value.length > 0 && errorMsgs.value === "") {
          response.value = false;
          overlay.value = true;
          let basket = [];
          selected.value.forEach((element) => {
            const {
              // id,
              student_id,
              header_campus_course_session: { course_id },
              grade_point,
              header_campus_course_session_id,
              header_campus_course_session: { header },
            } = element;

            basket.push({
              // id: id,
              student_id: student_id,
              type: selectedType.value.type,
              amount: amount.value,
              user_id: user.value.id,
              course_id: course_id,
              grade_point: parseFloat(grade_point).toFixed(2),
              header_campus_course_session_id: header_campus_course_session_id,
              header: header,
            });
          });

          assignStudentResit(basket)
            .then(() => {
              msgHeader.value = "Success";
              msgBody.value = "Saved successfully";
              msgIcon.value = "mdi-check-circle";
              color.value = "success";
            })
            .catch((e) => {
              msgIcon.value = "mdi-close-circle";
              overlay.value = false;
              color.value = "error";
              if (e.response.status === 403) {
                msgBody.value = e.response.data.message;
              } else if (e.response.status === 423) {
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
              } else
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
              // msgBody.value = e.response.data.message;
            })
            .finally(() => {
              overlay.value = false;
              form.value.reset();
              response.value = true;
              selected.value.length = 0;
            });
        }
      };

      provide("color", color);

      const quantity = ref(0);
      const amountOther = ref(parseFloat(0).toFixed(2));

      watch(
        () => quantity.value,
        () => {
          if (quantity.value >= 1) {
            saveOther.value = false;
            return;
          }
          saveOther.value = true;
        }
      );

      const decrement = () => {
        if (quantity.value >= 1) {
          --quantity.value;
          amountOther.value = parseFloat(
            quantity.value * selectedType.value.amount
          ).toFixed(2);
          return;
        }
      };

      const increment = () => {
        ++quantity.value;
        amountOther.value = parseFloat(
          quantity.value * selectedType.value.amount
        ).toFixed(2);
      };

      const saveOtherMisc = async () => {
        response.value = false;
        overlay.value = true;
        await Api()
          .post("/savemisctrans", {
            type: selectedType.value.type,
            amount: amountOther.value,
            student_id: index_number.value,
          })
          .then(() => {
            msgIcon.value = "mdi-check-circle";
            msgHeader.value = "Success";
            color.value = "success";
            msgBody.value = "Payment Has Been Saved Successfully";
            selected.value.length = 0;
          })
          .catch((e) => {
            msgIcon.value = "mdi-close-circle";
            msgHeader.value = "Error";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                break;
              case 403:
                msgBody.value = e.response.data.message;
                break;
              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            response.value = true;
            overlay.value = false;
            form.value.reset();
            quantity.value = 0;
            selected.value.length = 0;
          });
      };

      return {
        saveOtherMisc,
        debouncesearchStudent,
        ...toRefs(searchStudentHeader),
        basketWithIndex,
        saveresitBtn,
        getters_misc_fees,
        decrement,
        increment,
        quantity,
        amountOther,
      };
    },
  });
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.082);
  }
</style>
